import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";

import NiceModal from '@ebay/nice-modal-react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './components/hooks/useAuth';
import { Provider } from 'react-redux'; 
import store from './components/utils/Data'; 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    
    <React.StrictMode>
      <NiceModal.Provider>
        <Provider store={store}>
          <BrowserRouter>
            <AuthProvider>
              <App />
            </AuthProvider>
          </BrowserRouter>
        </Provider>
      </NiceModal.Provider>
    </React.StrictMode> 
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();