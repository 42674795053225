import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { CancelOutlined } from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";

export default function ZoneNumberModal({
  handleVegetationCase,
  indices,
  setNumberOfZones,
  showZoneNumberModal,
  setShowZoneNumberModal,
}) {
  const [number, setNumber] = useState("");
  const [error, setError] = useState("");

  const handleNumberChange = (event) => {
    const value = event.target.value;
    setNumber(value);
    setError("");
  };

  const handleSave = async () => {
    const intValue = parseInt(number, 10);

    if (isNaN(intValue) || intValue < 3 || intValue > 8) {
      setError("Please select a valid number between 3 and 8");
      return;
    }
    setNumberOfZones(intValue);
    setShowZoneNumberModal(false);
    handleVegetationCase(indices, intValue);
  };

  return (
    <Dialog
      open={showZoneNumberModal}
      onClose={() => setShowZoneNumberModal(false)}
    >
      <Box
        sx={{
          width: "100%",
          bgcolor: "#28282B",
          border: "1px solid #000",
          boxShadow: 24,
          p: 2,
          color: "white",
        }}
      >
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">Select Number of Zones</Typography>
          <CancelOutlined
            color={"primary"}
            onClick={() => setShowZoneNumberModal(false)}
            style={{ cursor: "pointer" }}
          />
        </Box>
        <DialogContent>
          <Box style={{ width: "100%", display: "flex" }}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                width: "300px",
                marginLeft: "10px",
              }}
            >
              <TextField
                select
                label="Number of Zones"
                variant="outlined"
                fullWidth
                value={number}
                onChange={handleNumberChange}
                error={!!error}
                helperText={error}
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#B4B4B8",
                    },
                  },
                }}
                InputLabelProps={{
                  style: { color: "#B4B4B8" },
                }}
                InputProps={{
                  style: { color: "white" },
                }}
              >
                {[3, 4, 5, 6, 7, 8].map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    style={{
                      backgroundColor: "#28282B",
                      color: "white",
                      paddingTop: "0",
                      paddingBottom: "0",
                      
                    }}
                  >
                    <span style={{padding:5}}>{option}</span>
                  </MenuItem>
                ))}
              </TextField>
              {/* Save Button */}
              <Button variant="contained" onClick={handleSave}>
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
}
