import InnerCard from "./InnerCard";
import OuterCard from "./OuterCard";

export default function FinalCard ({imageURL,titleText,bodyText,leftPosition,rightPosition}) {
    return(
        <>
        <OuterCard imageURL={imageURL}/>
        <InnerCard titleText={titleText} bodyText={bodyText} leftPosition={leftPosition} rightPosition={rightPosition}/>
        </>
    )
}