import React from "react";
import { Card, CardContent, Typography, Button, Box } from "@mui/material";
import { useWindowSize } from "@uidotdev/usehooks";
export default function InnerCard({titleText,bodyText,leftPosition,rightPosition}) {
const windowSize = useWindowSize();
  return (
    <Card
      style={{
        padding: "20px",
        borderRadius: "20px",
        width: "70%",
        backgroundColor: "#212a33",
        position: "absolute",
        bottom: -30,
        left: leftPosition && 30,
        right : rightPosition && 30
      }}
    >
      <CardContent>
        {/* <img
          src={svgURL}
          style={{ marginBottom: "10px" }}
        /> */}
        <Typography
          variant="h6"
          component="div"
          color={"white"}
          mb={3}
          textAlign={"left"}
          style={{ fontWeight: "bold" }}
        >
          <strong>
            {titleText}
          </strong>
        </Typography>
        <Typography
          variant="body1"
          component="div"
          color={"grey"}
          mb={1}
          textAlign={"left"}
          style={{ fontWeight: "bold" }}
        >
          <strong>
            {bodyText}
          </strong>
        </Typography>
        {/* <Box style={{ display: "flex" }}>
          <Button
            variant="contained"
            style={{
              marginRight: "8px",
              backgroundColor: "#f27e30",
              width:"50%",
            }}
            size="large"
          >
            Try Now
          </Button>
          <Button variant="outlined" style={{  width: "50%" }} size="large">
            Learn More
          </Button>
        </Box> */}
      </CardContent>
    </Card>
  );
}
