import { Box } from "@mui/material";
import CustomCarousel from "./CustomCarousel";
import CustomCard from "./CustomCard";
import { useWindowSize } from "@uidotdev/usehooks";
import PRECISION_AGRICULTURE from "../../public/images/precision_agrilculture.jpg";
export default function Registration() {
  const size = useWindowSize();
  const slides = [
    {
      title: "Manage your fields remotely",
      content:
        "Monitor the state of your crops right from the office, learn about the slightest changes on-the-spot, and make fast and reliable decisions on field treatment",
    },
    {
      title: "Save on costs by up to 30% with VRA approach",
      content:
        "This tool can identify up to 7 zones with different productivity rates and create differential fertilizing, sowing, and irrigation maps, thus saving you a lot of time and resources.",
    },
    {
      title: "Full-fledged weather analytics at your fingertips",
      content:
        "Correlate weather with vegetation indices which are the tell-tale signs of how weather conditions influence the health of your crops.",
    },
  ];
  return (
    <Box style={{ display: "flex" }}>
      <Box
        style={{
          display : size.width >  1024 ? "block"  : "none",
          width: "50%",
          backgroundImage:
            `url(${PRECISION_AGRICULTURE})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
        }}
      >
        <CustomCarousel slides={slides} />
      </Box>
      <Box style={{ width: size.width >  1024 ? "50%"  : "100%", background: "#181F26" ,height:'100vh',display:'flex',alignItems:'center'}}>   
        <CustomCard />
      </Box>
    </Box>
  );
}
