import { Box, Grid, List, ListItem, Typography, Collapse } from "@mui/material";
import { useWindowSize } from "@uidotdev/usehooks";
import service_1 from "../../public/images/service_1.png";
import service_2 from "../../public/images/service_2.png";
import service_3 from "../../public/images/service_3.png";
import FinalCard from "./FinalCard";
import SmallerCard from "./SmallerCard";
import HomeCollapse from "./HomeCollaps";

export default function HomeComponent2() {
  const windowSize = useWindowSize();
  return (
    <div id="about">
      <Grid container component="main">
        <Grid item xs={12} style={{ backgroundColor: "#181f26" }}>
          <Box
            pt={5}
            pb={5}
            pr={2}
            pl={2}
            style={{
              margin: "0 auto",
              marginTop: "40px",
              marginBottom: "10px",
              width:
                windowSize.width < 992
                  ? "95%"
                  : windowSize.width < 1025
                  ? "85%"
                  : windowSize.width < 1960
                  ? "65%"
                  : "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography
              variant="h4"
              component="div"
              color={"white"}
              mb={1}
              style={{ fontWeight: "bold", color: "#faa63d" }}
            >
              <strong>What we do</strong>
            </Typography>
            <Typography
              variant="body1"
              component="div"
              textAlign={"justify"}
              mb={5}
              style={{ color: "rgb(193, 207, 219)" }}
            >
              By harnessing the power of remote sensing, we are committed to
              fostering sustainable development, enhancing resource management,
              and contributing to a deeper understanding of our planet's
              intricate dynamics. Join us on our mission to unlock the full
              potential of Earth observation and drive positive change for a
              better, more sustainable future. At HISS Tech, we are at the
              forefront of integrating remote sensing, the Internet of Things
              (IoT), and data analytics to pioneer sustainability solutions. Our
              remote sensing capabilities allow us to capture detailed and
              dynamic data about the Earth's surface, offering vital insights
              into climate change, land utilization, and resource management. By
              weaving this data with our advanced IoT infrastructure, we
              establish a network of sensors that deliver real-time
              environmental intelligence, monitoring everything from energy
              consumption to natural habitat conditions. Our expertise in data
              analytics transforms this information into actionable insights,
              enabling us to predict trends, identify efficiencies, and
              recommend sustainable practices. We empower businesses,
              municipalities, and organizations to not only track their
              environmental impact but also to make informed decisions that
              contribute to a sustainable future. With HISS Tech, you're not
              just adopting cutting-edge technology; you're investing in a
              sustainable planet.
            </Typography>

            <Typography
              variant="h4"
              component="div"
              color={"white"}
              mb={1}
              style={{ fontWeight: "bold", color: "#faa63d" }}
            >
              <strong>Vision</strong>
            </Typography>
            <Typography
              variant="body1"
              component="div"
              textAlign={"justify"}
              mb={5}
              style={{ color: "rgb(193, 207, 219)" }}
            >
              We envision a world where technology empowers communities,
              businesses, and governments to achieve unprecedented levels of
              sustainability, resilience, and equity. Through our unwavering
              commitment to innovation, collaboration, and ethical practices, we
              aim to create a lasting positive impact on our planet, ensuring a
              thriving ecosystem and a prosperous, sustainable future for all.
            </Typography>
            <Typography
              variant="h4"
              component="div"
              color={"white"}
              mb={1}
              style={{ fontWeight: "bold", color: "#faa63d" }}
            >
              <strong>Mission</strong>
            </Typography>
            <Typography
              variant="body1"
              component="div"
              textAlign={"justify"}
              mb={3}
              style={{ color: "rgb(193, 207, 219)" }}
            >
              Our mission is to develop and deliver innovative technological
              solutions that advance sustainability across food production,
              natural resource management, and environmental protection. We
              empower communities, businesses, Governments, and organizations to
              adopt sustainable practices, reduce their ecological footprint,
              and enhance their operational resilience. Through continuous
              research, ethical practices, and strategic partnerships, we strive
              to lead the transformation towards a sustainable future, fostering
              a harmonious relationship between technology and the natural
              world.
            </Typography>
            <Typography
              variant="h5"
              component="div"
              color={"white"}
              style={{ fontWeight: "bold", color: "#faa63d" }}
            >
              <strong>Sustainable Development Goals</strong>
            </Typography>
            <Typography
              variant="body1"
              component="div"
              textAlign={"justify"}
              mb={5}
              style={{ color: "rgb(193, 207, 219)" }}
            >
              HISS Tech addresses the following Global Goals for Sustainable
              Development
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ backgroundColor: "#181f26" }}>
          <Box
            pb={5}
            pr={2}
            pl={2}
            style={{
              margin: "0 auto",
              marginBottom: "10px",
              width:
                windowSize.width < 992
                  ? "95%"
                  : windowSize.width < 1025
                  ? "85%"
                  : windowSize.width < 1960
                  ? "65%"
                  : "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography
              variant="h4"
              component="div"
              color={"white"}
              mb={1}
              style={{ fontWeight: "bold", color: "#faa63d" }}
            >
              <strong>Scope</strong>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ backgroundColor: "#181f26" }}>
          <Box
            style={{
              position: "relative",
              borderRadius: "50px",
              margin: "0 auto",
              marginBottom: "40px",
              width:
                windowSize.width < 992
                  ? "95%"
                  : windowSize.width < 1025
                  ? "85%"
                  : windowSize.width < 1960
                  ? "65%"
                  : "50%",
            }}
          >
            {windowSize.width < 1300 ? (
              <SmallerCard
                imageURL={service_1}
                titleText="Agriculture Monitoring and Precision Agriculture"
                bodyText="Our company's remote sensing-based precision agriculture services are designed to transform the agricultural sector by providing advanced, data-driven insights that optimize farming practices. By leveraging the power of remote sensing technology, we enable farmers and agribusinesses to monitor crop health, soil conditions, and environmental factors with unprecedented accuracy and detail. This information empowers them to make informed decisions, enhancing crop yields, reducing waste, and improving the overall efficiency of their operations. Our services are not just about adopting new technology; they're about embracing a future where agriculture is sustainable, productive, and in harmony with the environment, ensuring prosperity for today's farmers and generations to come."
              />
            ) : (
              <FinalCard
                imageURL={service_1}
                titleText="Agriculture Monitoring and Precision Agriculture"
                bodyText="Our company's remote sensing-based precision agriculture services are designed to transform the agricultural sector by providing advanced, data-driven insights that optimize farming practices. By leveraging the power of remote sensing technology, we enable farmers and agribusinesses to monitor crop health, soil conditions, and environmental factors with unprecedented accuracy and detail. This information empowers them to make informed decisions, enhancing crop yields, reducing waste, and improving the overall efficiency of their operations. Our services are not just about adopting new technology; they're about embracing a future where agriculture is sustainable, productive, and in harmony with the environment, ensuring prosperity for today's farmers and generations to come."
                leftPosition={true}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} style={{ backgroundColor: "#181f26" }}>
          <Box
            style={{
              position: "relative",
              borderRadius: "50px",
              margin: "0 auto",
              marginTop: "40px",
              marginBottom: "40px",
              width:
                windowSize.width < 992
                  ? "95%"
                  : windowSize.width < 1025
                  ? "85%"
                  : windowSize.width < 1960
                  ? "65%"
                  : "50%",
            }}
          >
            {windowSize.width < 1300 ? (
              <SmallerCard
                imageURL={service_2}
                titleText="Empower Sustainable Development: Innovative Land and Water Management Services Tailored for Tomorrow"
                bodyText="Our company excels in delivering state-of-the-art land and water management services, integrating remote sensing, Geographic Information Systems (GIS), and automation to champion sustainable development. Utilizing remote sensing, we capture detailed environmental data, which, when analysed through our sophisticated GIS, offers comprehensive insights into land and water use efficiency. Automation plays a pivotal role in translating these insights into actionable strategies, streamlining processes for optimal resource management. This synergy of technologies enables our clients to make informed decisions, ensuring the sustainable utilization and conservation of natural resources. Our commitment is to empower businesses, communities, and governments to not only meet their development objectives but also to do so in a way that safeguards the environment for future generations."
              />
            ) : (
              <FinalCard
                imageURL={service_2}
                titleText="Empower Sustainable Development: Innovative Land and Water Management Services Tailored for Tomorrow"
                bodyText="Our company excels in delivering state-of-the-art land and water management services, integrating remote sensing, Geographic Information Systems (GIS), and automation to champion sustainable development. Utilizing remote sensing, we capture detailed environmental data, which, when analysed through our sophisticated GIS, offers comprehensive insights into land and water use efficiency. Automation plays a pivotal role in translating these insights into actionable strategies, streamlining processes for optimal resource management. This synergy of technologies enables our clients to make informed decisions, ensuring the sustainable utilization and conservation of natural resources. Our commitment is to empower businesses, communities, and governments to not only meet their development objectives but also to do so in a way that safeguards the environment for future generations."
                rightPosition={true}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} style={{ backgroundColor: "#181f26" }}>
          <Box
            style={{
              position: "relative",
              borderRadius: "50px",
              margin: "0 auto",
              marginTop: "40px",
              marginBottom: "50px",
              width:
                windowSize.width < 992
                  ? "95%"
                  : windowSize.width < 1025
                  ? "85%"
                  : windowSize.width < 1960
                  ? "65%"
                  : "50%",
            }}
          >
            {windowSize.width < 1300 ? (
              <SmallerCard
                imageURL={service_3}
                titleText="Monitor, Measure, and Monetize: Elevate Your Forest Management with Our Advanced Monitoring for Carbon Credits and Sustainability"
                bodyText="Our advanced forest monitoring services are designed to empower stakeholders in the management and conservation of forests, aligning with the dual goals of earning carbon credits and promoting sustainable development. Utilizing state-of-the-art technology, we provide precise monitoring and data analysis, enabling forest owners and managers to track carbon sequestration, assess forest health, and detect changes over time. This data not only facilitates the generation of carbon credits, offering financial incentives for conservation efforts, but also supports informed decision-making for sustainable forest management. Our approach ensures that forests are valued not just for their timber but as vital carbon sinks and biodiversity reservoirs, contributing to global environmental goals and sustainable development objectives."
              />
            ) : (
              <FinalCard
                imageURL={service_3}
                titleText="Monitor, Measure, and Monetize: Elevate Your Forest Management with Our Advanced Monitoring for Carbon Credits and Sustainability"
                bodyText="Our advanced forest monitoring services are designed to empower stakeholders in the management and conservation of forests, aligning with the dual goals of earning carbon credits and promoting sustainable development. Utilizing state-of-the-art technology, we provide precise monitoring and data analysis, enabling forest owners and managers to track carbon sequestration, assess forest health, and detect changes over time. This data not only facilitates the generation of carbon credits, offering financial incentives for conservation efforts, but also supports informed decision-making for sustainable forest management. Our approach ensures that forests are valued not just for their timber but as vital carbon sinks and biodiversity reservoirs, contributing to global environmental goals and sustainable development objectives."
                leftPosition={true}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} style={{ backgroundColor: "#181f26" }}>
          <Box
            pt={5}
            pb={5}
            pr={2}
            pl={2}
            style={{
              margin: "0 auto",
              marginTop: "40px",
              marginBottom: "10px",
              width:
                windowSize.width < 992
                  ? "95%"
                  : windowSize.width < 1025
                  ? "85%"
                  : windowSize.width < 1960
                  ? "65%"
                  : "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography
              variant="h5"
              component="div"
              color={"white"}
              mb={2}
              style={{ fontWeight: "bold", color: "#faa63d" }}
            >
              <strong>Ready to go applications</strong>
            </Typography>
            <Typography
              variant="body1"
              component="div"
              textAlign={"justify"}
              style={{ color: "rgb(193, 207, 219)" }}
            >
              Crop monitoring web and mobile versions are almost ready to be
              launched. Features include: Any farm in the world can be tracked
              via European Satellite and gives information about the following
              parameters and perform machine learning algorithm to guide farmers
              for precision agriculture.
            </Typography>
            <List style={{ color: "rgb(193, 207, 219)" }}>
              <ListItem>• Crop health/vigor</ListItem>
              <ListItem>• Crop moisture</ListItem>
              <ListItem>• Crop nitrogen</ListItem>
              <ListItem>• Chlorophyll in crop (crop growth)</ListItem>
            </List>
            <Typography
              variant="body1"
              component="div"
              textAlign={"justify"}
              mb={3}
              style={{ color: "rgb(193, 207, 219)" }}
            >
              Also agrometeorological analysis are provided.
            </Typography>
            <Typography
              variant="h5"
              component="div"
              color={"white"}
              mb={2}
              style={{ fontWeight: "bold", color: "#faa63d" }}
            >
              <strong>Services</strong>
            </Typography>
            <Typography
              variant="body1"
              component="div"
              textAlign={"justify"}
              mb={3}
              style={{ color: "rgb(193, 207, 219)" }}
            >
              Bespoke services and applications on following thematic areas of
              agriculture and forestry:
            </Typography>
            <HomeCollapse title="Mapping and Monitoring Food security">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>• regional crop area maps</ListItem>
                <ListItem>• yield prediction at regional scales</ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Precision Agriculture">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>• Soil analysis and mapping</ListItem>
                <ListItem>• Crop health monitoring</ListItem>
                <ListItem>
                  • Variable rate technology (VRT) for planting and
                  fertilization
                </ListItem>
                <ListItem>• Yield mapping and analysis</ListItem>
                <ListItem>• Irrigation management</ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Crop Monitoring and Management">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Satellite and drone-based crop health assessment
                </ListItem>
                <ListItem>• Pest and disease detection</ListItem>
                <ListItem>• Growth stage monitoring</ListItem>
                <ListItem>• Remote sensing for nutrient management</ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Farm Management Solutions">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>• Integrated farm management software</ListItem>
                <ListItem>
                  • GIS-based farm planning and record-keeping
                </ListItem>
                <ListItem>
                  • GIS-based farm planning and record-keeping
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Weather and Climate Analysis">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>• Weather forecasting and monitoring</ListItem>
                <ListItem>• Climate impact assessment on crop yield</ListItem>
                <ListItem>• Drought and flood risk assessment</ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Field Mapping and Surveying">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • High-resolution field mapping using drones and satellites
                </ListItem>
                <ListItem>• Boundary and area measurement</ListItem>
                <ListItem>• Terrain and topography analysis</ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Forest Management">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>• Forest inventory and assessment</ListItem>
                <ListItem>• Biomass and carbon stock estimation</ListItem>
                <ListItem>• Forest health monitoring</ListItem>
                <ListItem>• Illegal logging detection</ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Sustainable Land Use Planning">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Land suitability analysis for different crops
                </ListItem>
                <ListItem>• Agroforestry planning and management</ListItem>
                <ListItem>• Soil erosion and conservation mapping</ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Irrigation and Water Management">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>• Water resource mapping and management</ListItem>
                <ListItem>• Irrigation system design and optimization</ListItem>
                <ListItem>• Soil moisture monitoring</ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Environmental Impact Assessment">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Conducting environmental baseline studies and impact
                  assessments.
                </ListItem>
                <ListItem>
                  • Using remote sensing to monitor environmental changes and
                  compliance with regulations.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Green Supply Chain Management">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Traceability solutions for agricultural products
                </ListItem>
                <ListItem>• Logistics and transportation optimization</ListItem>
                <ListItem>• Market analysis and demand forecasting</ListItem>
              </List>
            </HomeCollapse>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ backgroundColor: "#181f26" }}>
          <Box
            pb={5}
            pr={2}
            pl={2}
            style={{
              margin: "0 auto",

              marginBottom: "10px",
              width:
                windowSize.width < 992
                  ? "95%"
                  : windowSize.width < 1025
                  ? "85%"
                  : windowSize.width < 1960
                  ? "65%"
                  : "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography
              variant="h5"
              component="div"
              color={"white"}
              mb={3}
              style={{ fontWeight: "bold", color: "#faa63d" }}
            >
              <strong>Mineral Exploration</strong>
            </Typography>
            <HomeCollapse title="Remote Sensing and Satellite Imagery">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Utilizing satellite imagery and aerial surveys to detect
                  surface anomalies and alterations indicative of
                  mineralization.
                </ListItem>
                <ListItem>
                  • Applying multispectral and hyperspectral imaging for mineral
                  identification.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Drone Surveys">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Using drones for high-resolution aerial surveys and mapping.
                </ListItem>
                <ListItem>
                  • Conducting drone-based geophysical surveys and sampling.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Geophysical Surveys">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Conducting ground and airborne geophysical surveys (e.g.,
                  magnetic, gravity, seismic, electromagnetic) to detect
                  subsurface mineral deposits.
                </ListItem>
                <ListItem>
                  • Processing and interpreting geophysical data to create
                  subsurface models.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Mapping">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Creating detailed geological maps using remote sensing and
                  GIS technology.
                </ListItem>
                <ListItem>
                  • Analyzing surface geology to identify potential mineral
                  deposits.
                </ListItem>
                <ListItem>
                  • Performing soil, rock, and water sampling for geochemical
                  analysis.
                </ListItem>
                <ListItem>
                  • Using portable XRF (X-ray fluorescence) analyzers for rapid
                  on-site geochemical assessments.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="3D Modeling and Visualization">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Creating 3D geological models to visualize mineral deposits.
                </ListItem>
                <ListItem>
                  • Using virtual reality (VR) and augmented reality (AR) for
                  immersive exploration experiences.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Data Integration and Management">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Integrating various data sources (geological, geophysical,
                  geochemical) into a centralized database.
                </ListItem>
                <ListItem>
                  • Providing data management solutions for efficient
                  exploration project planning and execution.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Machine Learning and AI">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Applying machine learning algorithms to analyze exploration
                  data and predict mineralization zones.
                </ListItem>
                <ListItem>
                  • Using AI for automated pattern recognition and anomaly
                  detection.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Exploration Software Development">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Developing custom software solutions for exploration project
                  management and data analysis.
                </ListItem>
                <ListItem>
                  • Offering GIS and data visualization tools tailored for
                  mineral exploration.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Environmental Impact Assessment">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Conducting environmental baseline studies and impact
                  assessments.
                </ListItem>
                <ListItem>
                  • Using remote sensing to monitor environmental changes and
                  compliance with regulations.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Resource Estimation and Reporting">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Estimating mineral resources and reserves using
                  industry-standard methodologies.
                </ListItem>
                <ListItem>
                  • Preparing technical reports compliant with international
                  reporting standards (e.g., NI 43-101, JORC).
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Hydrogeological Studies">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Assessing groundwater resources and hydrogeological
                  conditions in exploration areas.
                </ListItem>
                <ListItem>
                  • Monitoring water quality and quantity impacts from
                  exploration activities.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Regulatory Compliance and Permitting">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Assisting with the preparation of permit applications and
                  compliance documentation.
                </ListItem>
                <ListItem>
                  • Providing solutions for tracking regulatory requirements and
                  deadlines.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Community Engagement and CSR">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Supporting community engagement and corporate social
                  responsibility (CSR) initiatives.
                </ListItem>
                <ListItem>
                  • Using GIS to map and address community concerns and land use
                  issues.
                </ListItem>
              </List>
            </HomeCollapse>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ backgroundColor: "#181f26" }}>
          <Box
            pb={5}
            pr={2}
            pl={2}
            style={{
              margin: "0 auto",

              marginBottom: "10px",
              width:
                windowSize.width < 992
                  ? "95%"
                  : windowSize.width < 1025
                  ? "85%"
                  : windowSize.width < 1960
                  ? "65%"
                  : "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography
              variant="h5"
              component="div"
              color={"white"}
              mb={3}
              style={{ fontWeight: "bold", color: "#faa63d" }}
            >
              <strong>Carbon Management and Offsetting</strong>
            </Typography>

            <HomeCollapse title="Carbon Footprint Analysis">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Conducting comprehensive assessments of an organization’s
                  carbon emissions.
                </ListItem>
                <ListItem>
                  • Identifying major sources of carbon emissions and
                  opportunities for reduction.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Carbon Monitoring and Reporting">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Implementing systems for continuous monitoring of carbon
                  emissions.
                </ListItem>
                <ListItem>
                  • Providing tools for detailed reporting and compliance with
                  regulatory requirements.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Carbon Offset Solutions">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Facilitating carbon offset programs, including project
                  identification, validation, and verification.
                </ListItem>
                <ListItem>
                  • Offering platforms for purchasing and trading carbon
                  credits.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Energy Efficiency Solutions">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Developing and implementing technologies to improve energy
                  efficiency in buildings, industrial processes, and
                  transportation.
                </ListItem>
                <ListItem>
                  • Providing analytics and recommendations for reducing energy
                  consumption.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Renewable Energy Integration">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Assisting in the integration of renewable energy sources
                  such as solar, wind, and biomass.
                </ListItem>
                <ListItem>
                  • Offering solutions for optimizing the use and storage of
                  renewable energy.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Sustainable Supply Chain Management">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Providing tools and strategies to reduce carbon emissions
                  throughout the supply chain.
                </ListItem>
                <ListItem>
                  • Implementing sustainable procurement practices and logistics
                  optimization.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Carbon Accounting Software">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Developing software solutions for tracking and managing
                  carbon emissions.
                </ListItem>
                <ListItem>
                  • Offering platforms for calculating, analyzing, and
                  visualizing carbon data.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Environmental Impact Assessment">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Conducting assessments to evaluate the carbon impact of
                  projects and operations.
                </ListItem>
                <ListItem>
                  • Providing recommendations to minimize environmental
                  footprints.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Climate Risk Analysis">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Assessing the risks posed by climate change to organizations
                  and infrastructure.
                </ListItem>
                <ListItem>
                  • Developing strategies for mitigating climate-related risks.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Carbon Neutral Certification">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Assisting organizations in achieving carbon-neutral
                  certification.
                </ListItem>
                <ListItem>
                  • Providing verification and validation services for carbon
                  neutrality claims.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Sustainable Agriculture Practices">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Implementing technologies to reduce carbon emissions in
                  agricultural practices.
                </ListItem>
                <ListItem>
                  • Promoting carbon sequestration through soil management and
                  reforestation.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Corporate Sustainability Strategy">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Developing comprehensive sustainability strategies for
                  organizations.
                </ListItem>
                <ListItem>
                  • Setting targets and action plans for carbon reduction and
                  sustainability goals.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Public Awareness and Education">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Creating platforms and campaigns to raise awareness about
                  carbon emissions and climate change.
                </ListItem>
                <ListItem>
                  • Providing educational resources and training programs on
                  sustainability practices.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Regulatory Compliance and Advocacy">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Assisting organizations in understanding and complying with
                  carbon-related regulations.
                </ListItem>
                <ListItem>
                  • Advocating for policies and initiatives that support carbon
                  reduction and sustainability.
                </ListItem>
              </List>
            </HomeCollapse>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ backgroundColor: "#181f26" }}>
          <Box
            pb={5}
            pr={2}
            pl={2}
            style={{
              margin: "0 auto",

              marginBottom: "10px",
              width:
                windowSize.width < 992
                  ? "95%"
                  : windowSize.width < 1025
                  ? "85%"
                  : windowSize.width < 1960
                  ? "65%"
                  : "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography
              variant="h5"
              component="div"
              color={"white"}
              mb={3}
              style={{ fontWeight: "bold", color: "#faa63d" }}
            >
              <strong>Disaster Management</strong>
            </Typography>
            <Typography
              variant="body1"
              component="div"
              textAlign={"justify"}
              mb={3}
              style={{ color: "rgb(193, 207, 219)" }}
            >
              Utilizing geospatial data for disaster prediction, response, and
              recovery efforts.
            </Typography>
            <HomeCollapse title="Hazard Mapping and Modeling">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Creating detailed maps of areas prone to natural hazards
                  like floods, earthquakes, landslides, and tsunamis.
                </ListItem>
                <ListItem>
                  • Developing predictive models for hazard occurrence and
                  impact.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Early Warning Systems">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Implementing real-time monitoring systems for natural
                  hazards.
                </ListItem>
                <ListItem>
                  • Developing and deploying early warning systems for
                  communities and authorities.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Risk Assessment and Analysis">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Conducting comprehensive risk assessments to identify
                  vulnerable areas.
                </ListItem>
                <ListItem>
                  • Analyzing potential impacts of various disaster scenarios on
                  infrastructure, populations, and economies.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Disaster Preparedness Planning">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Assisting in the development of disaster preparedness plans
                  and strategies.
                </ListItem>
                <ListItem>
                  • Providing training and simulations for emergency response
                  teams and communities.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Remote Sensing and Satellite Monitoring">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Utilizing satellite imagery and remote sensing data to
                  monitor environmental changes and detect early signs of
                  disasters.
                </ListItem>
                <ListItem>
                  • Assessing damage and impact post-disaster using
                  high-resolution imagery.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Geospatial Data Integration">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Integrating various geospatial datasets to provide a
                  comprehensive view of disaster risks.
                </ListItem>
                <ListItem>
                  • Developing geospatial databases and platforms for disaster
                  management.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Emergency Response Coordination">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Supporting the coordination of emergency response efforts
                  through geospatial tools.
                </ListItem>
                <ListItem>
                  • Providing real-time situational awareness and resource
                  allocation tools.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Infrastructure Resilience Analysis">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Assessing the vulnerability of critical infrastructure to
                  flooding.
                </ListItem>
                <ListItem>
                  • Recommending measures to enhance infrastructure resilience
                  and mitigate flood risks.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Community-Based Risk Reduction">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Engaging with communities to develop localized risk
                  reduction strategies.
                </ListItem>
                <ListItem>
                  • Providing tools and platforms for community risk mapping and
                  awareness.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Post-Disaster Recovery and Reconstruction">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Supporting post-disaster damage assessment and needs
                  analysis.
                </ListItem>
                <ListItem>
                  • Assisting in the planning and monitoring of reconstruction
                  efforts.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Climate Change Adaptation">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Analyzing the impacts of climate change on disaster risks.
                </ListItem>
                <ListItem>
                  • Developing adaptation strategies to reduce vulnerability to
                  climate-related hazards.
                </ListItem>
              </List>
            </HomeCollapse>
            <HomeCollapse title="Policy Support and Advocacy">
              <List style={{ color: "rgb(193, 207, 219)" }}>
                <ListItem>
                  • Providing data and analysis to support disaster risk
                  reduction policies.
                </ListItem>
                <ListItem>
                  • Advocating for the integration of disaster risk reduction in
                  urban planning and development.
                </ListItem>
              </List>
            </HomeCollapse>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ backgroundColor: "#181f26" }}>
          <Box
            pb={5}
            pr={2}
            pl={2}
            style={{
              margin: "0 auto",

              marginBottom: "10px",
              width:
                windowSize.width < 992
                  ? "95%"
                  : windowSize.width < 1025
                  ? "85%"
                  : windowSize.width < 1960
                  ? "65%"
                  : "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography
              variant="h5"
              component="div"
              color={"white"}
              mb={3}
              style={{ fontWeight: "bold", color: "#faa63d" }}
            >
              <strong>Flood Monitoring and Management</strong>
            </Typography>
            <HomeCollapse title="Flood Risk Mapping">
            <List style={{ color: "rgb(193, 207, 219)" }}>
              <ListItem>•	Creating detailed flood risk maps using GIS and remote sensing data.</ListItem>
              <ListItem>•	Identifying flood-prone areas and potential impact zones.</ListItem>
            </List>
            </HomeCollapse>
            <HomeCollapse title="Real-Time Monitoring Systems">
            <List style={{ color: "rgb(193, 207, 219)" }}>
              <ListItem>•	Implementing sensor networks for real-time monitoring of water levels in rivers, lakes, and reservoirs.</ListItem>
              <ListItem>•	Using satellite and drone imagery for continuous monitoring of flood conditions.</ListItem>
            </List>
            </HomeCollapse>
            <HomeCollapse title="Early Warning Systems">
            <List style={{ color: "rgb(193, 207, 219)" }}>
              <ListItem>•	Developing and deploying early warning systems that provide alerts to communities and authorities.</ListItem>
              <ListItem>•	Integrating weather forecasts and hydrological data to predict flood events.</ListItem>
            </List>
            </HomeCollapse>
            <HomeCollapse title="Hydrological Modeling">
            <List style={{ color: "rgb(193, 207, 219)" }}>
              <ListItem>•	Building hydrological models to simulate flood scenarios and predict flood behavior.</ListItem>
              <ListItem>•	Using machine learning algorithms to improve model accuracy and predict flood extents.</ListItem>
            </List>
            </HomeCollapse>
            <HomeCollapse title="Data Integration and Management">
            <List style={{ color: "rgb(193, 207, 219)" }}>
              <ListItem>•	Collecting and integrating data from various sources (e.g., weather stations, satellite imagery, sensor networks).</ListItem>
              <ListItem>•	Providing platforms for centralized data management and analysis.</ListItem>
            </List>
            </HomeCollapse>
            <HomeCollapse title="Emergency Response Planning">
            <List style={{ color: "rgb(193, 207, 219)" }}>
              <ListItem>•	Supporting the development of emergency response plans for flood events.</ListItem>
              <ListItem>•	Providing tools for real-time coordination and resource allocation during flood emergencies.</ListItem>
            </List>
            </HomeCollapse>
            <HomeCollapse title="Public Awareness and Education">
            <List style={{ color: "rgb(193, 207, 219)" }}>
              <ListItem>•	Creating platforms and campaigns to raise awareness about carbon emissions and climate change.</ListItem>
              <ListItem>•	Providing educational resources and training programs on sustainability practices.</ListItem>
            </List>
            </HomeCollapse>
            <HomeCollapse title="Infrastructure Resilience Analysis">
            <List style={{ color: "rgb(193, 207, 219)" }}>
              <ListItem>•	Assessing the vulnerability of critical infrastructure to different hazards.</ListItem>
              <ListItem>•	Recommending measures to enhance infrastructure resilience and mitigate risks.</ListItem>
            </List>
            </HomeCollapse>
            <HomeCollapse title="Post-Flood Damage Assessment">
            <List style={{ color: "rgb(193, 207, 219)" }}>
              <ListItem>•	Using remote sensing and GIS tools to assess flood damage.</ListItem>
              <ListItem>•	Providing detailed reports on the extent and impact of flooding for recovery planning.</ListItem>
            </List>
            </HomeCollapse>
            <HomeCollapse title="Urban Flood Management">
            <List style={{ color: "rgb(193, 207, 219)" }}>
              <ListItem>•	Implementing solutions for managing stormwater in urban areas.</ListItem>
              <ListItem>•	Designing and optimizing drainage systems to reduce urban flooding.</ListItem>
            </List>
            </HomeCollapse>
            <HomeCollapse title="Floodplain Management">
            <List style={{ color: "rgb(193, 207, 219)" }}>
              <ListItem>•	Assisting in the development and implementation of floodplain management policies.</ListItem>
              <ListItem>•	Using geospatial data to support land-use planning and zoning regulations.</ListItem>
            </List>
            </HomeCollapse>
            <HomeCollapse title="Climate Change Impact Assessment">
            <List style={{ color: "rgb(193, 207, 219)" }}>
              <ListItem>•	Analyzing the impact of climate change on flood risk and frequency.</ListItem>
              <ListItem>•	Developing adaptation strategies to mitigate the effects of climate change on flooding.</ListItem>
            </List>
            </HomeCollapse>
            <HomeCollapse title="Community-Based Flood Management">
            <List style={{ color: "rgb(193, 207, 219)" }}>
              <ListItem>•	Engaging with communities to develop localized flood management strategies.</ListItem>
              <ListItem>•	Providing tools for community-based flood risk mapping and monitoring.</ListItem>
            </List>
            </HomeCollapse>
            <HomeCollapse title="Insurance and Risk Financing">
            <List style={{ color: "rgb(193, 207, 219)" }}>
              <ListItem>•	Providing data and analytics to support flood insurance and risk financing products.</ListItem>
              <ListItem>•	Developing risk models for insurers to assess flood risk.</ListItem>
            </List>
            </HomeCollapse>
            <HomeCollapse title="Sustainable Water Management">
            <List style={{ color: "rgb(193, 207, 219)" }}>
              <ListItem>•	Implementing integrated water resource management (IWRM) practices to reduce flood risk.</ListItem>
              <ListItem>•	Promoting nature-based solutions such as wetland restoration and reforestation to enhance flood resilience.</ListItem>
            </List>
            </HomeCollapse>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ backgroundColor: "#181f26" }}>
          <Box
            pb={5}
            pr={2}
            pl={2}
            style={{
              margin: "0 auto",

              marginBottom: "10px",
              width:
                windowSize.width < 992
                  ? "95%"
                  : windowSize.width < 1025
                  ? "85%"
                  : windowSize.width < 1960
                  ? "65%"
                  : "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography
              variant="h5"
              component="div"
              color={"white"}
              mb={3}
              style={{ fontWeight: "bold", color: "#faa63d" }}
            >
              <strong>Transportation and Logistics</strong>
            </Typography>
            <Typography
              variant="body1"
              component="div"
              textAlign={"justify"}
              mb={3}
              style={{ color: "rgb(193, 207, 219)" }}
            >
              Optimizing routes, managing transportation networks, and improving logistics.
            </Typography>
            <HomeCollapse title="Route Optimization">
            <List style={{ color: "rgb(193, 207, 219)" }}>
              <ListItem>•	Developing algorithms and software for optimal route planning.</ListItem>
              <ListItem>•	Real-time traffic monitoring and dynamic rerouting to avoid congestion.</ListItem>
            </List>
            </HomeCollapse>
            <HomeCollapse title="Fleet Management">
            <List style={{ color: "rgb(193, 207, 219)" }}>
              <ListItem>•	Implementing GPS tracking and telematics for vehicle monitoring.</ListItem>
              <ListItem>•	Managing vehicle maintenance schedules and fuel consumption.</ListItem>
            </List>
            </HomeCollapse>
            <HomeCollapse title="Supply Chain Visibility">
            <List style={{ color: "rgb(193, 207, 219)" }}>
              <ListItem>•	Providing end-to-end visibility of goods in transit.</ListItem>
              <ListItem>•	Implementing real-time tracking solutions for shipments.</ListItem>
            </List>
            </HomeCollapse>
            <HomeCollapse title="Logistics Planning and Coordination">
            <List style={{ color: "rgb(193, 207, 219)" }}>
              <ListItem>•	Developing systems for coordinating multiple modes of transport (e.g., road, rail, sea, air).</ListItem>
              <ListItem>•	Optimizing warehouse operations and inventory management.</ListItem>
            </List>
            </HomeCollapse>
            <HomeCollapse title="Data Analytics and Reporting">
            <List style={{ color: "rgb(193, 207, 219)" }}>
              <ListItem>•	Analyzing transportation and logistics data to identify trends and improve efficiency.</ListItem>
              <ListItem>•	Generating detailed reports on performance metrics and KPIs.</ListItem>
            </List>
            </HomeCollapse>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ backgroundColor: "#181f26" }}>
          <Box
            pb={5}
            pr={2}
            pl={2}
            style={{
              margin: "0 auto",

              marginBottom: "10px",
              width:
                windowSize.width < 992
                  ? "95%"
                  : windowSize.width < 1025
                  ? "85%"
                  : windowSize.width < 1960
                  ? "65%"
                  : "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography
              variant="h5"
              component="div"
              color={"white"}
              mb={3}
              style={{ fontWeight: "bold", color: "#faa63d" }}
            >
              <strong>Training and Development</strong>
            </Typography>
            <HomeCollapse title="Real Estate and Property Management">
            <Typography
              variant="body1"
              component="div"
              textAlign={"justify"}
              mb={5}
              style={{ color: "rgb(193, 207, 219)" }}
            >
            Supporting real estate development, property management, and land use planning.
            </Typography>
            </HomeCollapse>
            <HomeCollapse title="Location-Based Services (LBS)">
            <Typography
              variant="body1"
              component="div"
              textAlign={"justify"}
              mb={5}
              style={{ color: "rgb(193, 207, 219)" }}
            >
            Providing services and solutions based on the geographical location of users.
            </Typography>
            </HomeCollapse>
            <HomeCollapse title="Urban Planning and Smart Cities">
            <Typography
              variant="body1"
              component="div"
              textAlign={"justify"}
              mb={5}
              style={{ color: "rgb(193, 207, 219)" }}
            >
           Using geospatial technology to support urban development and smart city initiatives.
            </Typography>
            </HomeCollapse>
            <HomeCollapse title="Environmental Monitoring">
            <Typography
              variant="body1"
              component="div"
              textAlign={"justify"}
              mb={5}
              style={{ color: "rgb(193, 207, 219)" }}
            >
          Tracking and analyzing environmental changes and impacts.
            </Typography>
            </HomeCollapse>
            <HomeCollapse title="Utilities and Infrastructure">
            <Typography
              variant="body1"
              component="div"
              textAlign={"justify"}
              mb={5}
              style={{ color: "rgb(193, 207, 219)" }}
            >
       Managing infrastructure networks like water, electricity, and telecommunications.
            </Typography>
            </HomeCollapse>
            <HomeCollapse title="Natural Resource Management">
            <Typography
              variant="body1"
              component="div"
              textAlign={"justify"}
              mb={5}
              style={{ color: "rgb(193, 207, 219)" }}
            >
      Overseeing the sustainable use and conservation of natural resources.
            </Typography>
            </HomeCollapse>
            <HomeCollapse title="Public Health and Epidemiology">
            <Typography
              variant="h5"
              component="div"
              color={"white"}
              style={{ fontWeight: "bold", color: "#f27e30" }}
            >
              <strong>Public Health and Epidemiology</strong>
            </Typography>
            </HomeCollapse>
            <HomeCollapse title="Military and Defense">
            <Typography
              variant="body1"
              component="div"
              textAlign={"justify"}
              mb={5}
              style={{ color: "rgb(193, 207, 219)" }}
            >
      Supporting defense operations and strategic planning with geospatial intelligence.
            </Typography>
            </HomeCollapse>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
