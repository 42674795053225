// LegendComponent.js

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Box, IconButton } from "@mui/material";
import { NDVIColor } from "./constants";
import {
  ArrowDownward,
  ArrowForward,
  ExpandMore,
  InfoOutlined,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUpSharp,
} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  legend: {
    right: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    padding: theme.spacing(1.5),
    backgroundColor: "#28282B", // Dark background color
    border: "1px solid #444", // Dark border color
    borderRadius: "4px",
    width: "20%",
    color: "#fff", // Text color
    width: "100%",
    overflowY: "scroll",
    maxHeight: "320px",
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
  },
  legendColor: {
    width: "20px",
    height: "20px",
    marginRight: theme.spacing(1),
    borderRadius: "5px",
  },

  indexView: {
    padding: "15px",
    color: "white",
    fontWeight: "bold",
  },
}));

function SLegendComponent({ selectedIndex }) {
  const [legendData, setLegendData] = useState(null);
  const classes = useStyles();
  const [collapsed, setCollapsed] = React.useState(false);

  useEffect(() => {
    const MapCustomLegend = async () => {
      const colorModule = await import(`./constants`);
      const color = colorModule[selectedIndex + "Legend"];

      setLegendData(
        color.colors.map((values, index) => (
          <div key={index} className={classes.legendItem}>
            <div
              className={classes.legendColor}
              style={{ backgroundColor: values[2] }}
            ></div>
            <span>{`${values[0]} \t ${values[1]}`}</span>
          </div>
        ))
      );
    };
    MapCustomLegend();
  }, [selectedIndex]);
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        <Typography className={classes.indexView}>{selectedIndex}</Typography>
        <IconButton aria-label="previous">
          {!collapsed ? (
            <ExpandMore color="black" style={{ color: "white" }} />
          ) : (
            <KeyboardArrowUpSharp color="black" style={{ color: "white" }} />
          )}
        </IconButton>
      </Box>

      <Box
        className={classes.legend}
        sx={{
          display: collapsed ? "none" : "block",
          transition: "display 0.3s ease",
        }}
      >
        {legendData}
      </Box>
    </Box>
  );
}

export default SLegendComponent;
