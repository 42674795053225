import CustomFooter from "./CustomFooter";
import CustomHeader from "./CustomHeader";
import HomeComponent1 from "./HomeComponent1";
import HomeComponent2 from "./HomeComponent2";
import HomeComponent3 from "./HomeComponent3";
import HomeComponent4 from "./HomeComponent4";

export default function Homepage() {
  return (
    <>
      <CustomHeader />
      <HomeComponent1 />
      <HomeComponent2 />
      {/* <HomeComponent3 /> */}
      <HomeComponent4 />
      <CustomFooter/>
    </>
  );
}
