import React, { useEffect } from "react";
import icon from "../utils/constants";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import { GeoSearchControl, OpenStreetMapProvider,EsriProvider } from "leaflet-geosearch";
const center = [30.33084, 71.24750];
const purpleOptions = { color: "white" };


export default function LeafletgeoSearch() {
  const map = useMap();
  useEffect(() => {
    const provider = new OpenStreetMapProvider();
    const searchControl = new GeoSearchControl({
      style: 'bar',
      provider, 
      notFoundMessage: 'Sorry, that address could not be found.',
      position:'topleft',
      showPopup: false, 
      autoClose: false,
      searchLabel: 'Search by District name or Lat,Lon',
      keepResult: false,
      showMarker: false,

    });
   

    map.addControl(searchControl);

    return () => map.removeControl(searchControl);
  }, []);

  return null;
}
