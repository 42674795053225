import React, { useState } from 'react';
import { Button, Collapse } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const HomeCollapse= ({ title, children}) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div style={{width: "100%", marginBottom: "20px"}}>
      <Button variant="contained" onClick={handleToggle} style={{display: "flex", justifyContent:  "space-between", width: '100%',textAlign: "left", backgroundColor: "transparent", fontWeight: "bold", color: "#faa63d", padding: "0", boxShadow: "none", fontSize: "1.2rem"}}>
        {title}
        {open ? (
         <KeyboardArrowUpIcon style={{ fontSize: '1.7rem' }} />
        ) : (
          <KeyboardArrowDownIcon style={{ fontSize: '1.7rem' }} />
        )}
      </Button>
      <Collapse in={open}>
      <div style={{padding: "10px 20px", borderRadius: "20px", backgroundColor: "rgb(33, 42, 51)",margin:   "10px 0 20px"}}>
          {children}
        </div>
      </Collapse>
    </div>
  );
};

export default HomeCollapse;
