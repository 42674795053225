import * as React from 'react';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import CircularProgress from '@mui/joy/CircularProgress';
import Typography from '@mui/joy/Typography';
import SvgIcon from '@mui/joy/SvgIcon';
import EmailIcon from '@mui/icons-material/Email';

export default function CustomContact() {
  return (
    <Card variant="solid" style={{backgroundColor:"rgb(17, 21, 27)"}} invertedColors>
      <CardContent orientation="horizontal">
        
        <CardContent style={{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'}} >
          <Typography level="h4">Write us at: </Typography>
          {/* <Typography level="h4">contact@escan-systems.com</Typography>
          <Typography level="h4"> / </Typography> */}
          <Typography level="h4">mobushir.riaz@icloud.com</Typography>

        </CardContent>
      </CardContent>
    </Card>
  );
}