import { Oval, RevolvingDot, Rings } from "react-loader-spinner";


export function Loader() {
    return (
        <Oval
            visible={true}
            height="50"
            radius="50"
            width="50"
            color="#fff"
            secondaryColor="#cccc"
            ariaLabel="revolving-dot-loading"
            wrapperStyle={{
                position: "absolute",
                top: "95%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 99999,
            }}
            wrapperClass=""
        />
    );
}