import * as L from "leaflet";
import { useMap } from "react-leaflet";
import { useEffect } from "react";

export default function KMLLayer({
  kmlUrl,
  setBoundingBox,
  setBounds,
  setCenterCoodinates,
  getStats,
  getViews,
  getViewsData,
}) {
  const map = useMap();
  var omnivore = require("leaflet-omnivore");
  useEffect(() => {
    updateMap();
  }, [kmlUrl]);
  const updateMap = () => {
    omnivore.kml(kmlUrl).on("ready", function () {
      const geoJsonLayer = this.toGeoJSON();
      L.geoJSON(geoJsonLayer, {
        style: {
          fillOpacity: 0,
          color: "transparent",
          fillColor: "transparent",
          opacity: 0,
        },
      });
      const layersBounds = this.getBounds();
      setBoundingBox(layersBounds);
      setBounds([
        [layersBounds._southWest.lat, layersBounds._southWest.lng],
        [layersBounds._northEast.lat, layersBounds._northEast.lng],
      ]);
      getStats(geoJsonLayer);
      getViews(
        geoJsonLayer,
        getViewsData.limit,
        getViewsData.page,
        getViewsData.startOfYear,
        getViewsData.tillDate,
        this.getBounds().toBBoxString(),
        true
      );
      const center = layersBounds.getCenter();
      setCenterCoodinates(center);
      map.flyTo(center, 20);
    });
  };
  return null;
}
