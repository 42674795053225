// LegendComponent.js

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Box, IconButton, Card, CardContent, colors } from "@mui/material";
import { NDVIColor } from "./constants";
import {
  ArrowDownward,
  ArrowForward,
  ExpandMore,
  InfoOutlined,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUpSharp,
} from "@mui/icons-material";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const useStyles = makeStyles((theme) => ({
  legend: {
    right: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    padding: '10px 16px 16px 16px',
    paddingTop: '12px',
    backgroundColor: "#28282B",
    border: "1px solid #444",
    borderRadius: "4px",
    width: "20%", 
    color: "#fff", // Text color
    width: "100%",
    overflowY: "scroll",
    maxHeight: "320px",
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
  },
  legendColor: {
    width: "20px",
    height: "20px",
    marginRight: theme.spacing(1),
    borderRadius: "5px",
  },

  indexView: {
    padding: "15px",
    color: "white",
    fontWeight: "bold",
  },
}));

function LegendComponent({ selectedIndex,zoningDrawing }) {
  const [legendData, setLegendData] = useState(null);
  const classes = useStyles();
  const [collapsed, setCollapsed] = React.useState(false);

  useEffect(() => {
    const MapCustomLegend = async () => {
      if(zoningDrawing){
        const array = [];
        const resultMap = {};
        zoningDrawing.features.map((feature,outerIndex)=>{
          const generatingColor = [zoningDrawing.features[outerIndex].properties.zone_area,zoningDrawing.features[outerIndex].properties.kmean,zoningDrawing.features[outerIndex].properties.color] 
          array.push(generatingColor);
        })
        array.forEach(item => {
          const [value0, value1, color] = item;
      
          if (!resultMap[color]) {
            resultMap[color] = [0, 0];
          }
      
          resultMap[color][0] += value0;
          resultMap[color][1] += value1;
        });
      
        const color = Object.keys(resultMap).map(color => {
          return [...resultMap[color], color];
        });
        color.sort((a, b) => b[1] - a[1]);
        setLegendData(
          color.map((values, index) => (
            <div key={index} className={classes.legendItem}>
              <div
                className={classes.legendColor}
                style={{
                  padding: "10px",
                  margin: "5px",
                  backgroundColor: values[2],
                }}
              ></div>
              <span>{`${(values[0]/10000).toFixed(2)} \t Ha (${((values[0]/10000).toFixed(2)*2.471).toFixed(2)} acres) , (${(values[1]).toFixed(2)})`}</span>
            </div>
          ))
        );
      }else{
        const colorModule = await import(`./constants`);
        const color = colorModule[selectedIndex + "Legend"];  
        setLegendData(
          color.colors.map((values, index) => (
            <div key={index} className={classes.legendItem}>
              <div
                className={classes.legendColor}
                style={{
                  padding: "10px",
                  margin: "5px",
                  backgroundColor: values[2],
                }}
              ></div>
              <span>{`${values[0]} \t ${values[1]}`}</span>
            </div>
          ))
        );
      }
    };
    MapCustomLegend();
  }, [selectedIndex]);
  return (
    <Card
      style={{
        width:'300px',
        height: collapsed ? "48px" : "350px",
        backgroundColor: "rgb(40,40,43)",
      }}
    >
      <CardContent
        style={{ overflowY: collapsed ? "unset" : "auto", maxHeight: "100%", padding: '10px 16px 16px 16px', }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="subtitle1"
            color="white"
            component="div"
            style={{ color: "white",lineHeight: "1"}}
          >
            <b style={{lineHeight: "28px"}}>{selectedIndex}</b>
          </Typography>
          <div
            onClick={() => setCollapsed(!collapsed)}
            style={{ cursor: "pointer" }}
          >
            {/* Up arrow when expanded, Down arrow when collapsed */}
            {collapsed == false ? (
              <ExpandMore style={{ color: "white", fontSize: "25px" }} />
            ) : (
              <KeyboardArrowUpSharp style={{ color: "white", fontSize: "25px" }} />
            )}
          </div>
          {/* Other components or actions you want to include */}
        </div>
        <Typography variant="body2" style={{ color: "white" }}>
          {legendData}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default LegendComponent;
  