import { useEffect, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useWindowSize } from "@uidotdev/usehooks";
import { Oval } from "react-loader-spinner";

export default function PrivacyPolicyComponent() {
  const windowSize = useWindowSize();
  const [privacyData, setPrivacyData] = useState(null);

  useEffect(() => {
    fetch("https://escan-systems.com/api/privacy-policy")
      .then((response) => response.json())
      .then((data) => {
        if (data && data.sections) {
          setPrivacyData(data);
        } else {
          console.error("Unexpected data format:", data);
        }
      })
      .catch((error) => console.error("Error fetching privacy policy:", error));
  }, []);

  return (
    <div id="Privacy">
      <Grid container component="main">
        <Grid item xs={12} style={{ backgroundColor: "#181f26" }}>
          <Box
            pt={5}
            pb={5}
            pr={2}
            pl={2}
            style={{
              margin: "0 auto",
              marginTop: "92px",
              marginBottom: "10px",
              width:
                windowSize.width < 992
                  ? "95%"
                  : windowSize.width < 1025
                  ? "85%"
                  : windowSize.width < 1960
                  ? "65%"
                  : "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography
              variant="h4"
              component="div"
              color={"white"}
              mb={1}
              style={{ fontWeight: "bold", color: "#f27e30" }}
            >
              <strong>Privacy Policy</strong>
            </Typography>

            {privacyData ? (
              <>
                <Typography
                  variant="body1"
                  component="div"
                  color={"grey"}
                  mb={3}
                  textAlign={"left"}
                  style={{ fontWeight: "bold" }}
                >
                  <strong>Updated on: {privacyData.updatedOn}</strong>
                </Typography>
                {privacyData.sections.map((section, index) => (
                  <div key={index}>
                    <Typography
                      variant="h5"
                      component="div"
                      color={"white"}
                      style={{ fontWeight: "bold", color: "#f27e30" }}
                    >
                      <strong>{section.title}</strong>
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      textAlign={"justify"}
                      mb={5}
                      style={{ color: "rgb(193, 207, 219)" }}
                    >
                      {section.content}
                    </Typography>
                  </div>
                ))}
                <Typography
                  variant="body1"
                  component="div"
                  color={"grey"}
                  mb={3}
                  textAlign={"left"}
                  style={{ fontWeight: "bold" }}
                >
                  <strong>Contact:</strong> {privacyData.contact}
                </Typography>
              </>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" width="100%">
                <Oval color="#f27e30" height={80} width={80} />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
